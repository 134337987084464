import { FileUploadContext } from "@/components/common/file-upload-context/file-uploads-context";
import { ProjectLoadingContext } from "@/components/common/project-provider/project-loading-context";
import { SceneEventsContext } from "@/components/common/scene-events-context";
import { UnitOfMeasureContext } from "@/components/common/unit-of-measure-context";
import { ViewRuntimeContext } from "@/components/common/view-runtime-context";
import { ErrorHandlersContext } from "@/errors/components/error-handling-context";
import { ModeDataContext } from "@/modes/mode-data-context";
import { DialogContext, ToastContext } from "@faro-lotv/flat-ui";
import { ApiClientContext } from "@faro-lotv/service-wires";
import { ThemeProvider, useTheme } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useContextBridge } from "@react-three/drei";
import { Html, HtmlProps } from "@react-three/drei/web/Html";
import { ReactReduxContext } from "react-redux";

/**
 * @returns an Html component that correctly bridge all the App contexts inside (theme too)
 */
export function AppAwareHtml({ children, ...rest }: HtmlProps): JSX.Element {
  const theme = useTheme();

  const ContextBridge = useContextBridge(
    ApiClientContext,
    DialogContext,
    ErrorHandlersContext,
    FileUploadContext,
    ProjectLoadingContext,
    ModeDataContext,
    ReactReduxContext,
    ToastContext,
    ViewRuntimeContext,
    SceneEventsContext,
    UnitOfMeasureContext,
  );

  return (
    <Html {...rest}>
      <ContextBridge>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </LocalizationProvider>
      </ContextBridge>
    </Html>
  );
}
