import { RootState } from "@/store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/** State specific for the creation of an annotation */
type CreateAnnotationState = {
  /**
   * If true the user has created at least one annotation since the Create Annotation tool has been activated.
   *
   * @default false
   */
  hasCreatedNewAnnotation: boolean;
};

const initialState: CreateAnnotationState = {
  hasCreatedNewAnnotation: false,
};

const createAnnotationSlice = createSlice({
  initialState,
  name: "createAnnotation",
  reducers: {
    setHasCreatedNewAnnotation(state, action: PayloadAction<boolean>) {
      state.hasCreatedNewAnnotation = action.payload;
    },
  },
});

export const { setHasCreatedNewAnnotation } = createAnnotationSlice.actions;

export const createAnnotationReducer = createAnnotationSlice.reducer;

/**
 * @returns true if the user has created a new annotation since the create annotation tool has been activated
 */
export function selectHasCreatedNewAnnotation({
  createAnnotation,
}: RootState): boolean {
  return createAnnotation.hasCreatedNewAnnotation;
}
