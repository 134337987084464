import {
  MultiRegistrationReport,
  validateMultiRegistrationReport,
} from "@/registration-tools/utils/multi-registration-report";
import { useAppDispatch } from "@/store/store-hooks";
import { assert, LOADING, MaybeLoading } from "@faro-lotv/foundation";
import { RegistrationRevision } from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";
import { reportConnections } from "../rendering/registration-connections";
import { addRevisionEdges } from "../store/revision-slice";

/**
 * @param revision The registration revision to load the report for.
 * @returns The loaded registration report, or `undefined` if it is still being loaded.
 */
export function useLoadRegistrationReport(
  revision: RegistrationRevision,
): MaybeLoading<MultiRegistrationReport> {
  const dispatch = useAppDispatch();
  const [registrationReport, setRegistrationReport] =
    useState<MaybeLoading<MultiRegistrationReport>>(LOADING);

  // Load the registration report
  useEffect(() => {
    const reportUrl = revision.reportUri;

    if (!reportUrl) {
      setRegistrationReport(undefined);
      return;
    }

    const abortController = new AbortController();

    fetch(reportUrl, {
      signal: abortController.signal,
    }).then(async (response) => {
      const registrationReport = await response.json();

      assert(
        validateMultiRegistrationReport(registrationReport),
        "Invalid registration report",
      );

      setRegistrationReport(registrationReport);

      dispatch(
        addRevisionEdges(reportConnections(registrationReport, revision)),
      );
    });

    return () => {
      abortController.abort("Cancel registration report fetching");
    };
  }, [dispatch, revision]);

  return registrationReport;
}
