import { RootState } from "@/store/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/** State specific for the mini map */
type MiniMapState = {
  /** Current size of the minimap */
  isFullscreen: boolean;
};

const initialState: MiniMapState = {
  isFullscreen: false,
};

const miniMapSlice = createSlice({
  initialState,
  name: "minimap",
  reducers: {
    setMiniMapFullscreen(state, action: PayloadAction<boolean>) {
      state.isFullscreen = action.payload;
    },
  },
});

export const { setMiniMapFullscreen } = miniMapSlice.actions;

export const miniMapReducer = miniMapSlice.reducer;

/**
 * @returns if the minimap should be rendered full screen
 * @param state RootState
 */
export function selectIsMinimapFullScreen(state: RootState): boolean {
  return state.miniMap.isFullscreen;
}
