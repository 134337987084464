import { GUID } from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import { IElementInVolume } from "@faro-lotv/service-wires";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AreaContentType } from "./area-contents-types";

/** A record holding an area's loaded contents by content type */
export type AreaContents = Partial<Record<AreaContentType, IElementInVolume[]>>;

export type AreaContentsState = {
  /** A record of all loaded area contents by areaId */
  areaContents: Record<GUID, AreaContents | undefined>;
};

export const AREA_CONTENTS_INITIAL_STATE: AreaContentsState = {
  areaContents: {},
};

const areaContentsSlice = createSlice({
  initialState: AREA_CONTENTS_INITIAL_STATE,
  name: "areaContents",
  reducers: {
    /**
     * Overwrites an area's contents for an AreaContentType.
     *
     * @param state current state
     * @param action action payload
     */
    setAreaContents(
      state,
      action: PayloadAction<{
        areaId: GUID;
        type: AreaContentType;
        elements: IElementInVolume[];
      }>,
    ) {
      const { areaId, type, elements } = action.payload;

      let areaRecord = state.areaContents[areaId];

      if (!areaRecord) {
        areaRecord = state.areaContents[areaId] = {};
      }

      areaRecord[type] = elements;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => AREA_CONTENTS_INITIAL_STATE);
  },
});

export const { setAreaContents } = areaContentsSlice.actions;

export const areaContentsReducer = areaContentsSlice.reducer;
