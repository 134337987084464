import { CameraParameters } from "@/components/r3f/utils/camera-parameters";
import { ExportModeTab } from "@/modes/export-mode/export-mode-types";
import { ModeNames } from "@/modes/mode";
import { BoxGizmoSide } from "@faro-lotv/lotv";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

/** State specific for the clipping box mode */
export type ExportModeState = {
  /** The mode to return to after the export */
  returnMode?: ModeNames;

  /** The camera to restore after the export */
  returnCamera?: CameraParameters;

  /** The initial camera target to use for the export workflow */
  initialTarget?: Vector3Tuple;

  /** The selected tab in the export mode */
  selectedTab: ExportModeTab;

  /** The selected side for the orthophoto export */
  selectedOrthophotoSide: BoxGizmoSide;
};

const EXPORT_MODE_INITIAL_STATE: ExportModeState = {
  selectedTab: ExportModeTab.pointCloud,
  selectedOrthophotoSide: BoxGizmoSide.zPositive,
};

const exportModeSlice = createSlice({
  initialState: EXPORT_MODE_INITIAL_STATE,
  name: "exportModeSlice",
  reducers: {
    prepareExport(
      state,
      action: PayloadAction<{
        returnMode: ModeNames;
        returnCamera: CameraParameters;
        initialTarget?: Vector3Tuple;
      }>,
    ) {
      state.returnMode = action.payload.returnMode;
      state.returnCamera = action.payload.returnCamera;
      state.initialTarget = action.payload.initialTarget;

      state.selectedTab = EXPORT_MODE_INITIAL_STATE.selectedTab;
      state.selectedOrthophotoSide =
        EXPORT_MODE_INITIAL_STATE.selectedOrthophotoSide;
    },

    /**
     * Sets the selected export tab
     *
     * @param state current application state
     * @param action the new tab to select
     */
    setSelectedExportModeTab(state, action: PayloadAction<ExportModeTab>) {
      state.selectedTab = action.payload;
    },

    /**
     * Sets the selected orthophoto side
     *
     * @param state current application state
     * @param action the new tab to select
     */
    setSelectedOrthophotoSide(state, action: PayloadAction<BoxGizmoSide>) {
      state.selectedOrthophotoSide = action.payload;
    },
  },
});

export const {
  prepareExport,
  setSelectedExportModeTab,
  setSelectedOrthophotoSide,
} = exportModeSlice.actions;

export const exportModeReducer = exportModeSlice.reducer;
