import {
  PointCloudAnalysisLabel,
  removeAnalysisLabel,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { FaroMenu, FaroMenuItem } from "@faro-lotv/flat-ui";
import { GUID, SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { Box } from "@mui/material";
import {
  MouseEvent,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { MOUSE, Plane, Vector3 } from "three";
import { AppAwareHtml } from "../app-aware-html";
import { MeasureLabel } from "../measurements/measure-label";

type AnalysisLableProps = {
  /** Analysis label object */
  label: PointCloudAnalysisLabel;

  /** Id of the analysis owns the label */
  analysisId: GUID;

  /** Reference plane to compute distance value */
  referencePlane: Plane;

  /** Unit of measure to use for the labels */
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;
};

/**
 * @returns Analysis label displays a deviation and located and the label position in 3D space.
 */
export function AnalysisLabel({
  label,
  analysisId,
  referencePlane,
  unitOfMeasure,
  parentRef,
}: AnalysisLableProps): JSX.Element | null {
  const position = useMemo(
    () => new Vector3().fromArray(label.position),
    [label.position],
  );
  const distance = useMemo(
    () => referencePlane.distanceToPoint(position),
    [position, referencePlane],
  );

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const onPointerDown = useCallback((ev: MouseEvent) => {
    if (ev.button !== MOUSE.RIGHT) return;
    // Need to stop propagation to prevent the scene context menu popup
    ev.stopPropagation();
  }, []);

  const onContextMenu = useCallback((ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsPopupOpen(true);
  }, []);

  const dispatch = useAppDispatch();
  const deleteLabel = useCallback(() => {
    dispatch(removeAnalysisLabel({ analysisId, labelId: label.id }));
    setIsPopupOpen(false);
  }, [analysisId, dispatch, label]);

  const closeMenu = useCallback(() => {
    setIsPopupOpen(false);
  }, []);

  return (
    <>
      <MeasureLabel
        index={0}
        visible
        position={position}
        parentRef={parentRef}
        distance={distance}
        unitOfMeasure={unitOfMeasure}
        active
        transparent={false}
        onPointerDown={onPointerDown}
        onContextMenu={onContextMenu}
        pointerEvents="auto"
      />
      {isPopupOpen && (
        <AnalysisLabelMenu
          position={position}
          parentRef={parentRef}
          onDeleteLabel={deleteLabel}
          closeMenu={closeMenu}
        />
      )}
    </>
  );
}

type AnalysisLabelMenuProps = {
  /** 3D position of the label menu in the scene */
  position: Vector3;

  /** The parent that the label menu should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;

  /** Callback when the Delete menu item is clicked */
  onDeleteLabel(): void;

  /** Function close this menu */
  closeMenu(): void;
};

/**
 * @returns A menu that appears when an analysis label is clicked.
 */
function AnalysisLabelMenu({
  position,
  parentRef,
  onDeleteLabel,
  closeMenu,
}: AnalysisLabelMenuProps): JSX.Element {
  const anchorEl = useRef(null);
  return (
    <AppAwareHtml
      position={position}
      portal={parentRef}
      style={{
        display: "block",
      }}
      zIndexRange={[0, 0]}
    >
      {/* Create a empty box as anchor for the popup menu */}
      <Box component="div" ref={anchorEl} />
      <FaroMenu dark open anchorEl={anchorEl.current} onClose={closeMenu}>
        <FaroMenuItem dark label="Delete" onClick={onDeleteLabel} />
      </FaroMenu>
    </AppAwareHtml>
  );
}
