import { blue, neutral } from "@faro-lotv/flat-ui";
import { Box } from "@mui/system";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { PercentCrop, ReactCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

/** used to describe parameters of the crop in % relatively to size of original image  */
export type RelativeCrop = Omit<PercentCrop, "unit">;

interface ImageCropProps {
  /** File currently selected */
  file: File;

  /** image scale (caused by rotation)  */
  scale: number;

  /** rotation angle in degrees  */
  rotation: number;

  /** if true crop is enabled  */
  isCropEnabled: boolean;

  /** switch crop to be enabled/disabled  */
  setCropEnabled(isCropEnabled: boolean): void;

  /**
   * method to set crop parameters
   *
   * @param rotation crop parameters (in % of original image)
   */
  setCrop(crop?: RelativeCrop): void;

  /** ratio width/height of original image - used to scale rotated image in preview  */
  widthToHeightRatio: number;

  /** switch crop data for preview */
  setPreviewCrop(percentCrop: PercentCrop | undefined): void;

  /** crop data for preview (note that it's not the same as crop data for actual image crop in case of rotated image) */
  previewCrop?: PercentCrop;

  /** switch crop to be visible/hidden  */
  setCropVisible(visible: boolean): void;

  // if true, crop is visible
  isCropVisible: boolean;
}

const fiftyPercents = 50;

/** @returns control to preview sheet image */
export function ImageCrop({
  file,
  scale,
  rotation,
  widthToHeightRatio,
  isCropEnabled,
  setCropEnabled,
  setCrop,
  setPreviewCrop,
  previewCrop,
  setCropVisible,
  isCropVisible,
}: ImageCropProps): JSX.Element {
  // This is needed to avoid continuously creating a new URL damaging performance
  const fileSource = useMemo(() => URL.createObjectURL(file), [file]);
  const cropRef = useRef<ReactCrop>(null);

  // Callback used to compute relative crop to be applied on original image from
  // crop parameters obtained from ReactCrop library
  const updateRelativeCrop = useCallback(
    (newCrop: PercentCrop) => {
      setPreviewCrop(newCrop);
      setCropVisible(isCropEnabled && !!previewCrop);

      const squareRatio = widthToHeightRatio * widthToHeightRatio;

      // When image in preview is rotated, it's rescaled in preview only, without changing canvas.
      // As result cropping parameters obtained from ReactCrop component are for rotated image,
      // but in coordinates of original images canvas. In order to apply crop correctly to rotated image
      // we need to pre-compute crop for rotated/scaled image.
      let scaledCrop: RelativeCrop | undefined;
      if (isCropEnabled) {
        scaledCrop = {
          x: newCrop.x,
          y: newCrop.y,
          width: newCrop.width,
          height: newCrop.height,
        };
        if (scale !== 1) {
          if (widthToHeightRatio > 1) {
            const scaledX =
              squareRatio * (newCrop.x - fiftyPercents) + fiftyPercents;
            scaledCrop.x = Math.min(100, Math.max(0, scaledX));
            let scaledWidth = newCrop.width * squareRatio;
            if (scaledX < 0) {
              scaledWidth += scaledX;
            }

            scaledCrop.width = Math.max(
              0,
              Math.min(scaledWidth, 100 - scaledCrop.x),
            );
          } else if (widthToHeightRatio < 1) {
            const scaledY =
              newCrop.y / squareRatio - fiftyPercents * (1 / squareRatio - 1);
            scaledCrop.y = Math.min(100, Math.max(0, scaledY));
            let scaledHeight = newCrop.height / squareRatio;
            if (scaledY < 0) {
              scaledHeight += scaledY;
            }

            scaledCrop.height = Math.max(
              0,
              Math.min(scaledHeight, 100 - scaledCrop.y),
            );
          }
        }

        if (!scaledCrop.width || !scaledCrop.height) scaledCrop = undefined;
      }

      setCrop(scaledCrop);
      setCropEnabled(!!newCrop.height && !!newCrop.width);
    },
    [
      isCropEnabled,
      previewCrop,
      scale,
      setCrop,
      setCropEnabled,
      setCropVisible,
      setPreviewCrop,
      widthToHeightRatio,
    ],
  );

  // crop shade color/opacity in ReactCrop are not accessible through css style.
  // but we can get access through parent reference and adjust that when reference is available
  // Reference to box changes every time when cropping box disappears/appears
  // so we have to trigger useEffect every time when cropping box become visible
  useEffect(() => {
    if (cropRef.current?.componentRef.current) {
      const rectMask = cropRef.current.componentRef.current.querySelector(
        ".ReactCrop__crop-mask rect",
      );

      if (rectMask) {
        rectMask.setAttribute("fill-opacity", "0.25");
      }
    }
  }, [isCropVisible]);

  return (
    <Box
      component="div"
      sx={{
        marginTop: "1rem",
        marginBottom: "1rem",
        width: "80%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // Cropping line
        "& .ReactCrop__crop-selection:not(.ReactCrop--no-animate .ReactCrop__crop-selection)":
          {
            backgroundImage: `
            linear-gradient(to right, ${blue[400]}  50%, ${neutral[0]} 50%),
            linear-gradient(to right, ${blue[400]}  50%, ${neutral[0]} 50%),
            linear-gradient(to bottom, ${blue[400]}  50%, ${neutral[0]} 50%),
            linear-gradient(to bottom, ${blue[400]}  50%, ${neutral[0]} 50%)`,
          },

        // Handles
        "& .ReactCrop__crop-selection .ReactCrop__drag-handle": {
          backgroundColor: `${blue[500]}`,
        },
      }}
    >
      <ReactCrop
        ref={cropRef}
        crop={previewCrop}
        onChange={(_, percentCrop) => updateRelativeCrop(percentCrop)}
      >
        <img
          alt="Crop image"
          src={fileSource}
          style={{ transform: `scale(${scale}) rotate(${rotation}deg)` }}
        />
      </ReactCrop>
    </Box>
  );
}
