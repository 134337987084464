import {
  EventType,
  ToggleUnitOfMeasureActionSource,
  ToggleUnitOfMeasureEventProperties,
} from "@/analytics/analytics-events";
import { useAppSelector } from "@/store/store-hooks";
import { useLocalStorage } from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { selectRootIElement } from "@faro-lotv/project-source";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";

type UnitOfMeasureContextData = {
  /** Current selected unit of measure to use */
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** Function to change the unit of measure to use */
  setUnitOfMeasure(unit: SupportedUnitsOfMeasure): void;
};

export const UnitOfMeasureContext = createContext<
  UnitOfMeasureContextData | undefined
>(undefined);

/**
 * @returns A component to register a context for the unit of measure of the project
 */
export function UnitOfMeasureContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const root = useAppSelector(selectRootIElement);
  const defaultUnit =
    root?.metaDataMap?.projectSettings?.unitSystem ?? "metric";

  const [unitOfMeasure, setUnitOfMeasure] = useLocalStorage(
    "unitOfMeasure",
    defaultUnit,
  );

  const contextValue = useMemo(
    () => ({
      unitOfMeasure,
      setUnitOfMeasure,
    }),
    [unitOfMeasure, setUnitOfMeasure],
  );

  return (
    <UnitOfMeasureContext.Provider value={contextValue}>
      {children}
    </UnitOfMeasureContext.Provider>
  );
}

/**
 * @returns The context for the current unit of measure of the project
 */
export function useUnitOfMeasureContext(): UnitOfMeasureContextData {
  const ctx = useContext(UnitOfMeasureContext);
  assert(
    ctx,
    "useUnitOfMeasureContext must be used within a UnitOfMeasureContexProvider",
  );
  return ctx;
}

/**
 * @param trackAnalyticsEvent Whether to track the event when the unit of measure is toggled
 * @param toggleSource reports from where units were changed
 * @returns The current unit of measure of the project and a toggle function to switch between metric and imperial
 */
export function useToggleUnitOfMeasure(
  trackAnalyticsEvent: boolean,
  toggleSource: ToggleUnitOfMeasureActionSource,
): {
  unitOfMeasure: SupportedUnitsOfMeasure;
  toggleUnitOfMeasure(): void;
} {
  const { unitOfMeasure, setUnitOfMeasure } = useUnitOfMeasureContext();

  const toggleUnitOfMeasure = useCallback(() => {
    const newUnitOfMeasure = unitOfMeasure === "metric" ? "us" : "metric";

    if (trackAnalyticsEvent) {
      Analytics.track<ToggleUnitOfMeasureEventProperties>(
        EventType.toggleUnitOfMeasure,
        {
          newValue:
            newUnitOfMeasure === "metric" ? newUnitOfMeasure : "imperial",
          via: toggleSource,
        },
      );
    }

    setUnitOfMeasure(newUnitOfMeasure);
  }, [setUnitOfMeasure, toggleSource, trackAnalyticsEvent, unitOfMeasure]);

  return { unitOfMeasure, toggleUnitOfMeasure };
}
