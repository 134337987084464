import { ToggleUnitOfMeasureActionSource } from "@/analytics/analytics-events";
import { useSceneEvents } from "@/components/common/scene-events-context";
import { useToggleUnitOfMeasure } from "@/components/common/unit-of-measure-context";
import { useActionBarAnchorPoint } from "@/hooks/use-actionbar-anchor-point";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { PointCloudObject } from "@/object-cache";
import { PointCloudAnalysis } from "@/store/point-cloud-analysis-tool-slice";
import { useAppSelector } from "@/store/store-hooks";
import {
  computeColormapParameters,
  getAnalysisReferencePlane,
} from "@/utils/colormap-analysis-utils";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { GridPlane, Z_TO_Y_UP_QUAT } from "@faro-lotv/app-component-toolbox";
import { AdaptivePointsMaterial } from "@faro-lotv/lotv";
import { useEffect, useMemo, useState } from "react";
import { Box3, Plane, Quaternion, Vector2, Vector3 } from "three";
import { AnalysisActionBar } from "./analysis-action-bar";
import { AnalysisLabel } from "./analysis-label";

type ColorMapAnalysisRendererProps = {
  /** Point cloud object used by analysis */
  pointCloud: PointCloudObject;

  /** The point cloud analysis to be rendered */
  analysis: PointCloudAnalysis;
};

/** @returns The renderer of a colormap analysis */
export function ColormapAnalysisRenderer({
  pointCloud,
  analysis,
}: ColorMapAnalysisRendererProps): JSX.Element | null {
  const polygon = useMemo(
    () => analysis.polygonSelection.map((p) => new Vector3().fromArray(p)),
    [analysis.polygonSelection],
  );

  const [planePosition, setPlanePosition] = useState<Vector3>();
  const [planeQuaternion, setPlaneQuaternion] = useState<Quaternion>();
  const extents = useMemo(() => {
    const box = new Box3().setFromPoints(polygon);
    const size = box.getSize(new Vector3()).length();
    return new Vector2(size, size);
  }, [polygon]);

  const referencePlane = useMemo(
    () => getAnalysisReferencePlane(analysis),
    [analysis],
  );

  useEffect(() => {
    if (!referencePlane) return;

    setPlanePosition(referencePlane.point);
    const quaternion = new Quaternion().setFromUnitVectors(
      new Vector3(0, 1, 0),
      referencePlane.normal,
    );
    quaternion.multiply(Z_TO_Y_UP_QUAT);
    setPlaneQuaternion(quaternion);
  }, [analysis, referencePlane]);

  const sceneEvents = useSceneEvents();

  // add dependency to worldMatrix to make sure to re-compute colormapParms if worldMatrix is changed
  const worldMatrix = useAppSelector(
    selectIElementWorldMatrix4(pointCloud.iElement.id),
  );

  useEffect(() => {
    const { material } = pointCloud;
    if (!(material instanceof AdaptivePointsMaterial)) {
      return;
    }
    const colormapParms = computeColormapParameters(pointCloud, analysis);
    if (!colormapParms) return;

    material.setColormap(colormapParms);
    // Sometime updating material does not trigger re-render PointCloudSubscene
    // Manually invalidate is required to make sure view is updated
    sceneEvents.invalidatePointCloudScene.emit();
    return () => {
      material.setColormap(undefined);
      sceneEvents.invalidatePointCloudScene.emit();
    };
  }, [
    analysis,
    pointCloud,
    sceneEvents.invalidatePointCloudScene,
    worldMatrix,
  ]);

  // Anchor point for the action bar
  const anchorPoint = useActionBarAnchorPoint(polygon);

  const { unitOfMeasure, toggleUnitOfMeasure } = useToggleUnitOfMeasure(
    true,
    ToggleUnitOfMeasureActionSource.colorAnalysisToolbar,
  );

  const labelContainer = useViewOverlayRef();

  const referencePlaneEquation = useMemo(
    () =>
      referencePlane
        ? new Plane().setFromNormalAndCoplanarPoint(
            referencePlane.normal,
            referencePlane.point,
          )
        : undefined,
    [referencePlane],
  );

  return (
    <>
      <AnalysisActionBar
        anchorPoint={anchorPoint}
        analysis={analysis}
        unitOfMeasure={unitOfMeasure}
        toggleUnitOfMeasure={toggleUnitOfMeasure}
      />
      {analysis.showReferencePlane && (
        <group position={planePosition} quaternion={planeQuaternion}>
          <GridPlane size={extents} />
        </group>
      )}
      {
        // Render labels
        analysis.labels.map((label) =>
          referencePlaneEquation ? (
            <AnalysisLabel
              key={label.id}
              label={label}
              analysisId={analysis.id}
              referencePlane={referencePlaneEquation}
              unitOfMeasure={unitOfMeasure}
              parentRef={labelContainer}
            />
          ) : null,
        )
      }
    </>
  );
}
