import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectCadSvfMetadata } from "@/store/cad/cad-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  blue,
  FaroDialog,
  FaroRadio,
  FaroRadioGroup,
  FaroText,
  FaroTooltip,
  fetchProjectIElements,
  selectIElement,
  selectIElementProjectApiLocalPose,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import { assert, GUID } from "@faro-lotv/foundation";
import { isIElementBimModelSection } from "@faro-lotv/ielement-types";
import {
  createMutationSetElementPosition,
  createMutationSetElementRotation,
  createMutationSetElementScale,
} from "@faro-lotv/service-wires";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { Matrix4 } from "three";
import {
  combineDirectConversion,
  getAllModelTransformations,
} from "../../cad-model-tree/cad-metadata-utility";

export type ChangeCadCsDialogProps = {
  /* true to display the dialog, false to keep it hidden */
  open: boolean;

  /** GUID of the Model being edited */
  idIElementModel3dStream: GUID;

  /** callback to be called when user close/cancel the dialog */
  onClose(): void;
};

/**
 * @returns component displaying a dialog asking user to change the coordinates system used for CAD
 */
export function ChangeCadCsDialog({
  open,
  idIElementModel3dStream,
  onClose,
}: ChangeCadCsDialogProps): JSX.Element | null {
  const store = useAppStore();
  const projectApi = useCurrentProjectApiClient();
  const { handleErrorWithToast } = useErrorHandlers();
  const { openToast } = useToast();
  const dispatch = useAppDispatch();
  const [isConfirmDisabled, setConfirmButtonDisabled] = useState(true);
  const [transformInProgress, setTransformInProgress] = useState(false);

  // Which coordinate system to apply to Cad
  const userChoice = useRef<OrientationType>();

  const metadata = useAppSelector(selectCadSvfMetadata);

  // pre compute transformations for each case
  const transformationsMatrix = useMemo(
    () => (metadata ? getAllModelTransformations(metadata) : undefined),
    [metadata],
  );

  // user's choice for whether we should we apply offset to model's Ref Point?
  const [offsetToRefPoint, setOffsetToRefPoint] = useState(false);

  // force the option to apply Ref Point offset (undefined mean listen to offsetToRefPoint; otherwise, ignore offsetToRefPoint)
  const [forceOffsetToRefPoint, setForceOffsetToRefPoint] = useState<boolean>();

  const enableOffsetToRefPoint =
    forceOffsetToRefPoint === undefined &&
    transformationsMatrix?.refPointInMeshCs;

  // Called when user pressed Apply button in the  dialog
  const applyChangeCadCs = useCallback(async (): Promise<void> => {
    const model3DStreamElement = selectIElement(idIElementModel3dStream)(
      store.getState(),
    );
    assert(model3DStreamElement, "Invalid CAD stream");
    const bimModelSectionElement = selectIElement(
      model3DStreamElement.parentId,
    )(store.getState());
    assert(
      bimModelSectionElement &&
        isIElementBimModelSection(bimModelSectionElement),
      "Invalid 3D Model Element in applyTransformationToCad",
    );

    /**
     * @returns the transformation to apply on the model according to user's choice; undefined if the user choice is invalid
     */
    function getMeshTransformation(): Matrix4 | undefined {
      switch (userChoice.current) {
        case OrientationType.useModelTrueNorth:
          return transformationsMatrix?.toModelTrueNorth;
        case OrientationType.useModelNorth:
          return transformationsMatrix?.toModelNorth;
        case OrientationType.useModelView:
          return transformationsMatrix?.toModelView;
        case OrientationType.useDefault:
          return new Matrix4().identity();
        case OrientationType.useModelRefCs:
          return transformationsMatrix?.toRefPoint;
        case OrientationType.useModelSystemCs:
          return transformationsMatrix?.toModelCS;
        default:
          return undefined;
      }
    }

    /**
     * @returns the new absolute transformation to apply to the CAD; undefined if the user choice is invalid
     */
    function getNewCadTransformation(): Matrix4 | undefined {
      const rotationMatrixFromModelToUserChoice = getMeshTransformation();
      if (!transformationsMatrix || !rotationMatrixFromModelToUserChoice) {
        return undefined;
      }
      if (
        offsetToRefPoint &&
        transformationsMatrix.refPointInMeshCs &&
        userChoice.current !== OrientationType.useModelRefCs
      ) {
        // generate transformation matrix translating origin of mesh to Ref Point
        const meshToRefPointTranslation = new Matrix4().makeTranslation(
          transformationsMatrix.refPointInMeshCs.clone().negate(),
        );
        // combine rotation and translation
        return combineDirectConversion(
          rotationMatrixFromModelToUserChoice,
          meshToRefPointTranslation,
        );
      }
      // return optional rotation without offset
      return rotationMatrixFromModelToUserChoice;
    }

    // transformation to apply to mesh (from exported mesh to new location of mesh)
    const relativeTransformMesh = getNewCadTransformation() ?? new Matrix4();

    const mutationTransform = selectIElementProjectApiLocalPose(
      bimModelSectionElement,
      relativeTransformMesh,
    )(store.getState());

    setTransformInProgress(true);

    // apply the mutation updating the CAD transformation
    try {
      const mutations = [
        createMutationSetElementPosition(
          bimModelSectionElement.id,
          mutationTransform.pos,
        ),
        createMutationSetElementRotation(
          bimModelSectionElement.id,
          mutationTransform.rot,
        ),
        createMutationSetElementScale(bimModelSectionElement.id, {
          x: 1,
          y: 1,
          z: 1,
        }),
      ];

      await projectApi.applyMutations(mutations);

      // Update the IElement tree
      await dispatch(
        fetchProjectIElements({
          fetcher: () =>
            projectApi.getAllIElements({
              // We only need to fetch the subtree starting from the modified element
              ancestorIds: [bimModelSectionElement.id],
            }),
        }),
      );

      openToast({
        title: "CAD transformation successfully applied",
        variant: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save new CAD transformation",
        error,
      });
    }

    setTransformInProgress(false);
  }, [
    handleErrorWithToast,
    projectApi,
    store,
    openToast,
    dispatch,
    idIElementModel3dStream,
    transformationsMatrix,
    offsetToRefPoint,
  ]);

  return (
    <FaroDialog
      title="Change Model Coordinate System"
      open={open}
      onConfirm={applyChangeCadCs}
      onCancel={onClose}
      isConfirmDisabled={isConfirmDisabled}
      dark
      confirmText="Apply"
      showXButton
      showSpinner={transformInProgress}
    >
      <Stack gap={3}>
        <Typography>
          Select the model's coordinate system to be used in Sphere XG
        </Typography>
        <FaroRadioGroup
          onChange={(v) => {
            userChoice.current = stringToOrientationType(v.target.value);
            setConfirmButtonDisabled(false);
            switch (userChoice.current) {
              case OrientationType.useModelRefCs:
                setForceOffsetToRefPoint(true);
                break;
              case OrientationType.useDefault:
              case OrientationType.useModelTrueNorth:
              case OrientationType.useModelNorth:
              case OrientationType.useModelView:
              case OrientationType.useModelSystemCs:
                setForceOffsetToRefPoint(undefined);
                break;
            }
          }}
        >
          <FaroTooltip title="Set same rotation as fresh import">
            <RadioButton
              value="useDefault"
              label="Use default orientation"
              disabled={false}
            />
          </FaroTooltip>
          <FaroTooltip title="Use model's Up as Sphere's Up/Z; use model's Front as Sphere's South/-Y">
            <RadioButton
              value="useModelView"
              disabled={!transformationsMatrix?.toModelView}
              label="Use model's view orientation"
            />
          </FaroTooltip>
          <FaroTooltip title="Use model's Up as Sphere's Up/Z; use model's Front as Sphere's South/-Y">
            <RadioButton
              value="useModelNorth"
              disabled={!transformationsMatrix?.toModelNorth}
              label="Use model's north orientation"
            />
          </FaroTooltip>
          <FaroTooltip title="Use model's Up as Sphere's Up/Z; use model's True North as Sphere's North/Y">
            <RadioButton
              value="useModelTrueNorth"
              disabled={!transformationsMatrix?.toModelTrueNorth}
              label="Use model's true north orientation"
            />
          </FaroTooltip>
          <FaroTooltip title="Use model's Z as Sphere's Up/Z; use model's Y as Sphere's North/Y">
            <RadioButton
              value="useModelSystemCs"
              disabled={!transformationsMatrix}
              label="Use model's system coordinates system"
            />
          </FaroTooltip>
          <FaroTooltip title="Use model's Ref CS">
            <RadioButton
              value="useModelRefCs"
              disabled={!transformationsMatrix?.toRefPoint}
              label="Use model's Ref coordinates system"
            />
          </FaroTooltip>
        </FaroRadioGroup>
        <FormControlLabel
          label={
            <FaroText
              variant="bodyL"
              dark
              sx={{ m: 3 }}
              color={enableOffsetToRefPoint ? "white" : "gray"}
            >
              Offset to model's Ref Point
            </FaroText>
          }
          control={
            <Checkbox
              disabled={
                !enableOffsetToRefPoint || !transformationsMatrix.toRefPoint
              }
              checked={forceOffsetToRefPoint ?? offsetToRefPoint}
              onChange={(ev) => setOffsetToRefPoint(ev.target.checked)}
              color="primary"
              sx={{
                p: 0,
                "& .MuiSvgIcon-root": {
                  fontSize: 20,
                  color: enableOffsetToRefPoint ? "white" : "gray",
                },
                "&.Mui-checked": {
                  "& .MuiSvgIcon-root": {
                    color: enableOffsetToRefPoint ? blue[300] : "gray",
                  },
                },
              }}
            />
          }
          sx={{ m: 0 }}
        />
      </Stack>
    </FaroDialog>
  );
}

type RadioButtonProps = {
  // Label displayed after the radio button
  label: string;

  // Value associated with the radio button (also used as aria-label)
  value: string;

  // true to disable the radio button
  disabled: boolean;
};

/**
 * @returns the FormControlLabel embedding the radio button used for each orientation choice
 */
function RadioButton({
  label,
  value,
  disabled,
}: RadioButtonProps): JSX.Element {
  return (
    <FormControlLabel
      value={value}
      control={<FaroRadio dark />}
      disabled={disabled}
      label={
        <FaroText variant="bodyL" color={disabled ? "gray" : "white"}>
          {label}
        </FaroText>
      }
      aria-label={value}
      sx={{ m: 0 }}
    />
  );
}
// List of possible CS orientations.
// Take note that the CS store in the IElement is not the same one shown to the user
// (e.g. Z is up from user point of view, but Y is up in the IElement)
enum OrientationType {
  useDefault = "useDefault",
  useModelView = "useModelView",
  useModelNorth = "useModelNorth",
  useModelTrueNorth = "useModelTrueNorth",
  useModelSystemCs = "useModelSystemCs",
  useModelRefCs = "useModelRefCs",
}

// Convert the string associated with one of the radio button to the associated OrientationType
function stringToOrientationType(s: string): OrientationType | undefined {
  switch (s) {
    case OrientationType.useModelTrueNorth:
      return OrientationType.useModelTrueNorth;
    case OrientationType.useModelNorth:
      return OrientationType.useModelNorth;
    case OrientationType.useModelView:
      return OrientationType.useModelView;
    case OrientationType.useDefault:
      return OrientationType.useDefault;
    case OrientationType.useModelSystemCs:
      return OrientationType.useModelSystemCs;
    case OrientationType.useModelRefCs:
      return OrientationType.useModelRefCs;
  }
}
