import { HorizontalResizeContainer } from "@/components/ui/resize-container";
import { useAppSelector } from "@/store/store-hooks";
import { FaroText, NoTranslate } from "@faro-lotv/flat-ui";
import { selectProjectName } from "@faro-lotv/project-source";
import { Stack } from "@mui/material";
import {
  selectRevisionEntities,
  selectScanTypeLabel,
} from "../store/revision-selectors";
import { ScanTree } from "./scan-tree/scan-tree";

/** @returns Sidebar to use throughout the data preparation tool. */
export function DataPreparationSidebar(): JSX.Element {
  const projectName = useAppSelector(selectProjectName) ?? "Project";
  const scanTypeLabel = useAppSelector(selectScanTypeLabel);

  return (
    <ScanTreeSidebar projectName={projectName} dataTitle={scanTypeLabel} />
  );
}

type ScanTreeSidebarProps = {
  /** The name of the project containing the revision. */
  projectName: string;

  /** The title for the data being shown to the user. */
  dataTitle: string;
};

/** @returns a sidebar with containing a ScanTree with customizable title and action buttons */
function ScanTreeSidebar({
  projectName,
  dataTitle,
}: ScanTreeSidebarProps): JSX.Element {
  const entities = useAppSelector(selectRevisionEntities);

  return (
    <HorizontalResizeContainer
      initialWidth={320}
      minWidth={200}
      maxWidth={500}
      handleSide="right"
    >
      <Stack
        justifyContent="space-between"
        flexShrink={0}
        sx={{
          width: "100%",
          height: "100%",
          px: 1.5,
          py: 3,
        }}
      >
        <Stack gap={2} height="100%">
          <Stack gap={1.5}>
            <NoTranslate>
              <FaroText variant="heading16">{projectName}</FaroText>
            </NoTranslate>
            <FaroText variant="heading14">{dataTitle}</FaroText>
          </Stack>

          <ScanTree entities={entities} />
        </Stack>
      </Stack>
    </HorizontalResizeContainer>
  );
}
