import { useTransparencySettingsContext } from "@/components/common/transparency-sliders/transparency-settings-context";
import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { isPointCloudObject } from "@/object-cache-type-guard";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName, setActiveSettingsMenu } from "@/store/ui/ui-slice";
import { selectHasWritePermission } from "@/store/user-selectors";
import { ClipSceneTool, ClipSceneToolProps } from "@/tools/clip-scene-tool";
import {
  PickingTools,
  PickingToolsProps,
  PickingToolsRef,
} from "@/tools/picking-tools";
import {
  useDeactivateToolOnUnmount,
  useToggleToolVisibility,
} from "@/tools/use-toggle-tool-visibility";
import { useNonExhaustiveEffect } from "@faro-lotv/app-component-toolbox";
import { forwardRef } from "react";

/**
 * This hook implements the logic to show the tools that the overview mode needs, according to the
 * user's settings and permissions. This hook also correctly deactivates all the tools and related
 * settings on unmount.
 *
 * @param isPointCloud Whether a point cloud is present among the active models
 */
function useOverviewToolsVisibility(isPointCloud: boolean): void {
  const dispatch = useAppDispatch();

  const { canWriteAnnotations } = useAnnotationPermissions();
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const canCreateArea = useAppSelector(selectHasFeature(Features.CreateArea));

  const transparency = useTransparencySettingsContext();

  // Clipping tool require write permission to allow the creation of new areas
  useToggleToolVisibility(
    ToolName.clipping,
    hasWritePermission && isPointCloud,
    canCreateArea,
    false,
  );

  // Export tool require write permission to store in the project the exported point cloud file
  useToggleToolVisibility(
    ToolName.export,
    hasWritePermission && isPointCloud,
    true,
    false,
  );

  useToggleToolVisibility(ToolName.clipScene, true, true, false);
  useToggleToolVisibility(
    ToolName.annotation,
    canWriteAnnotations,
    true,
    false,
  );
  useToggleToolVisibility(ToolName.measurement, true, true, false);
  useToggleToolVisibility(ToolName.opacity, true, true, false);

  useToggleToolVisibility(ToolName.analysis, isPointCloud, true, false);

  useDeactivateToolOnUnmount();

  // Here a non-exhaustive useEffect is used, with the purpose
  // that the effect should not be run when the `transparency`
  // context changes, but only when the mode scene is exited and unmounted.
  // Usage of a normal 'useEffect' causes the opacity sliders to
  // close abruptly when the user sets the two sliders to full opaque.
  useNonExhaustiveEffect(() => {
    return () => {
      dispatch(setActiveSettingsMenu(null));
      transparency.resetOpacities();
    };
  }, [dispatch]);
}

type OverviewToolsProps = PickingToolsProps & ClipSceneToolProps;

/** @returns the logic to controls what tool is active in 3D Overview Mode */
export const OverviewTools = forwardRef<PickingToolsRef, OverviewToolsProps>(
  function OverviewTools(
    {
      activeModels,
      onToolActiveChanged,
      areaBox,
      modelBox,
      clippingBoxChanging,
      clippingPlanesChanged,
    },
    ref,
  ): JSX.Element | null {
    const activeTool = useAppSelector(selectActiveTool);

    const isPointCloud =
      activeModels?.find((e) => isPointCloudObject(e)) !== undefined;

    useOverviewToolsVisibility(isPointCloud);

    return (
      <>
        <PickingTools
          ref={ref}
          activeModels={activeModels}
          onToolActiveChanged={onToolActiveChanged}
        />
        <ClipSceneTool
          active={activeTool === ToolName.clipScene}
          areaBox={areaBox}
          modelBox={modelBox}
          clippingBoxChanging={clippingBoxChanging}
          clippingPlanesChanged={clippingPlanesChanged}
        />
      </>
    );
  },
);
